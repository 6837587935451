<template>
  <div class="sidenav">
    <div class="sidenav__position sidenav__position--top">
      <div class="sidenav__items">
        <router-link :class="['sidenav__item', $route.meta.section == 'home' ? 'sidenav__item--active' : '']" :to="{ name: 'Home' }">
          <img class="sidenav__ico sidenav__ico--wh" src="@/assets/images/icons/ico_home--wh.svg">
          <img class="sidenav__ico sidenav__ico--bl" src="@/assets/images/icons/ico_home--bl.svg">
           Inicio
        </router-link>
        <router-link :class="['sidenav__item', $route.meta.section == 'events' ? 'sidenav__item--active' : '']" :to="{ name: 'Events' }">
          <img class="sidenav__ico sidenav__ico--wh" src="@/assets/images/icons/ico_calendar--wh.svg">
          <img class="sidenav__ico sidenav__ico--bl" src="@/assets/images/icons/ico_calendar--bl.svg">
           Calendario
        </router-link>        
      </div>
    </div>

    <div class="sidenav__position sidenav__position--bottom">
      <div class="sidenav__items">
        <router-link class="sidenav__item" :to="{ name: 'Caracteristicas' }">
          <img class="sidenav__ico sidenav__ico--wh" src="@/assets/images/icons/ico_d--white.svg">
          <img class="sidenav__ico sidenav__ico--bl" src="@/assets/images/icons/ico_d--black.svg">
            ¿Qué es dorapp?
        </router-link>
        <template v-if="$store.state.user.role_id">
          <a href="#" class="sidenav__item" @click.prevent="showNotificationDialog = true">
            <img class="sidenav__ico sidenav__ico--wh" src="@/assets/images/icons/ico_bell--wh.svg">
            <img class="sidenav__ico sidenav__ico--bl" src="@/assets/images/icons/ico_bell--bl.svg">
            Notificaciones
          </a>
          <router-link v-if="$store.state.user.role_id == 1" :class="['sidenav__item', $route.meta.section == 'sistema' ? 'sidenav__item--active' : '']" :to="{ name: 'GroupsAndPermissions' }">
            <img class="sidenav__ico sidenav__ico--wh" src="@/assets/images/icons/ico_settings--wh.svg">
            <img class="sidenav__ico sidenav__ico--bl" src="@/assets/images/icons/ico_settings--bl.svg">
            Sistema
          </router-link>
          <a href="#" class="sidenav__item sidenav__item--danger" @click.prevent="logout()">
            <img class="sidenav__ico sidenav__ico--wh" src="@/assets/images/icons/ico_logout--wh.svg">
            <img class="sidenav__ico sidenav__ico--bl" src="@/assets/images/icons/ico_logout--bl.svg">
            Salir
          </a>
        </template>
      </div>
    </div>
  </div>
  <NotificationDialog v-if="showNotificationDialog" @close="showNotificationDialog = false"/>
</template>

<script>
  import NotificationDialog from '@/components/NotificationsDialog.vue'
  export default {
    name: "SideNav",
    components: { NotificationDialog },
    
    data() {
      return {
        showNotificationDialog: false
      }
    },
    methods:{
      async logout() {
        if(this.$store.state.notification_token)
          await this.$axios.post('users/clear-token', {token: this.$store.state.notification_token})
        this.$router.push({ path: this.$route.path, query: { logout: true } })
      }
    }
  }
</script>