<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31">
      <section class="main-content">
        <form class="form profile-update" @submit.prevent="update()">
          
          <div class="profile-update__image-container">
            <div v-if="profile.imgUsu" class="user__image user__image--big">
              <img referrerpolicy="no-referrer" :src="profile.imgUsu">
            </div>
            <div v-else class="user__initials user__initials--big">
              {{profile.nomUsu?.slice(0,1)}}{{profile.apePatUsu?.slice(0,1)}}
            </div>
            <label class="btn btn--primary btn--outline btn--xs">
              <input type="file" @change="attachFile" accept=".jpg, .png">
              Actualizar foto de perfil
            </label>
          </div>
          <div class="grid grid-3">
            <div class="form__group">
              <label class="form__label" for="nomUsu">Nombre</label>
              <input class="form__input" type="text" id="nomUsu" v-model.trim="profile.nomUsu" :disabled="submitting" required>
            </div>
            <div class="form__group">
              <label class="form__label" for="apePatUsu">Primer Apellido</label>
              <input class="form__input" type="text" id="apePatUsu" v-model.trim="profile.apePatUsu" :disabled="submitting" required>
            </div>
            <div class="form__group">
              <label class="form__label" for="apeMatUsu">Segundo Apellido</label>
              <input class="form__input" type="text" id="apeMatUsu" v-model.trim="profile.apeMatUsu" :disabled="submitting" required>
            </div>
          </div>
          
          <div class="grid grid-2">
            <div class="form__group">
              <label class="form__label" for="linkedIn">Perfil LinkedIn <small class="text-alt">(Opcional)</small> </label>
              <input class="form__input" type="text" id="linkedIn" v-model.trim="profile.linkedIn" :disabled="submitting" placeholder="https://www.linkedin.com/in/TuCuentaDeLinkedIn">
            </div>
            <div class="form__group">
              <label class="form__label" for="publicEmail">Correo Público <small class="text-alt">(Opcional)</small> </label>
              <input class="form__input" type="text" id="publicEmail" v-model.trim="profile.publicEmail" :disabled="submitting">
            </div>
          </div>
          <div class="form__group">
            <label class="form__label" for="descUsu">Biografía</label>
            <textarea class="form__input" id="descUsu" v-model.trim="profile.descUsu" :disabled="submitting" required></textarea>
          </div>
          <div v-if="!submitting" class="form__actions">
            <button type="button" class="btn btn--primary btn--outline" @click.prevent="goBack">Cancelar</button>
            <button class="btn btn--primary" type="submit">Actualizar perfil</button>
          </div>
          <div v-else class="form__actions">
            <button type="button" class="btn btn--primary btn--outline" disabled>Cancelar</button>
            <button class="btn btn--primary" type="button" disabled>Actualizando...</button>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
export default {
  components: { SideNav },
  name: 'MyProfileEdit',
  data() {
    return {
      profile: [],
      id: null,
      submitting: false
    }
  },
  async created() {
    this.id = this.$route.params.id || this.$store.state.user.id
    this.profile = (await this.$axios.get(`Users/${this.$store.state.user.id}`)).data;
  },
  methods:{
    async update(){
      this.submitting = true
      const formData = new FormData()
      formData.append('nomUsu', this.profile.nomUsu)
      formData.append('apePatUsu', this.profile.apePatUsu)
      formData.append('apeMatUsu', this.profile.apeMatUsu)
      formData.append('descUsu', this.profile.descUsu)
      formData.append('linkedInUsu', this.profile.linkedIn)
      formData.append('publicEmailUsu', this.profile.publicEmail)
      if(this.profile.publicEmail && !this.isValidEmail(this.profile.publicEmail)){
        this.$toast.error('El correo utilizado no es válido')
        this.submitting = false
        return
      }
      const regex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm
      if(this.profile.linkedIn && !regex.test(this.profile.linkedIn)){
        this.$toast.error('El enlace a LinkedIn utilizado no es válido')
        this.submitting = false
        return
      }
      formData.append('imgUsu', this.newImg)
      /*
      console.log(formData)*/
      const res = (await this.$axios.post('Users/update', formData, {headers: {'Content-Type': 'multipart/form-data'}}))
      if(res.status == 200){
        if (this.profile.idUsu === this.$store.state.user.id)
            await this.$axios.get('init')
        this.$nextTick(() =>{
          if(this.$route.params.id == undefined){
            this.$toast.success('Tu perfil ha sido actualizado exitosamente')
            this.$router.push('/mi-perfil')
          }
          else{
            this.$toast.success('El perfil ha sido actualizado exitosamente')
              this.$router.push({name: 'Group', params: {groupId: this.profile.idTipoUsu.id}})
          }
        })
      }
      else
        this.$toast.error(res.data)
      this.submitting = false
    },
    goBack(){
      if(this.$route.params.id == undefined)
        this.$router.push('/mi-perfil')
      else
        this.$router.push({name: 'Group', params: {groupId: this.profile.idTipoUsu.id}})
    },
    isValidEmail(email){
      var validator = require("email-validator");
      return validator.validate(email);
    },
    attachFile(e) {
      this.profile.imgUsu = URL.createObjectURL(e.target.files[0])
      this.newImg = e.target.files[0]
    },
  }
}
</script>